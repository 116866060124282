const initState = {
  toggle: false
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
