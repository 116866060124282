import {
  UPDATE_ACTIVE_REQUEST,
  UPDATE_ACTIVE_ERROR,
  UPDATE_ACTIVE_SUCCESS,
  OTP_REGISTER_REQUEST,
  OTP_REGISTER_SUCCESS,
  OTP_REGISTER_ERROR,
  OTP_RESEND_REQUEST,
  OTP_RESEND_SUCCESS,
  OTP_RESEND_ERROR,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  SET_CODE
} from '@actions/card.action';

const initState = {
  isLoading: false,
  isLoadingSubmit: false,
  data: [],
  code: null
};

export default function cardReducer(state = initState, action) {
  switch (action.type) {
    case UPDATE_ACTIVE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_ACTIVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data
      };
    case UPDATE_ACTIVE_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case OTP_REGISTER_REQUEST:
      return {
        ...state,
        isLoadingSubmit: true
      };
    case OTP_REGISTER_SUCCESS:
      return {
        ...state,
        isLoadingSubmit: false,
        data: action.payload.data
      };
    case OTP_REGISTER_ERROR:
      return {
        ...state,
        isLoadingSubmit: false
      };

    case OTP_RESEND_REQUEST:
      return {
        ...state,
        isLoadingSubmit: true
      };
    case OTP_RESEND_SUCCESS:
      return {
        ...state,
        isLoadingSubmit: false,
        data: action.payload.data
      };
    case OTP_RESEND_ERROR:
      return {
        ...state,
        isLoadingSubmit: false
      };

    case SET_CODE:
      return {
        ...state,
        code: action.code
      };

    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoadingSubmit: true
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoadingSubmit: false,
        data: action.payload.data
      };
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        isLoadingSubmit: false
      };

    default:
      return state;
  }
}
