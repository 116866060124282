import axios from 'axios';
import { URL_API, GATEWAY_BASE_API_URL } from '@configs/keys';
import { getToken, getCompanySession } from '@helpers/token';
import { logoutUser } from './auth.action';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_RECEIPT_REQUEST = 'UPLOAD_RECEIPT_REQUEST';
export const UPLOAD_RECEIPT_SUCCESS = 'UPLOAD_RECEIPT_SUCCESS';
export const UPLOAD_RECEIPT_ERROR = 'UPLOAD_RECEIPT_ERROR';

export const uploadFile = data => {
  const token = getToken() || null;
  axios.defaults.headers.common.Authorization = `${token}`;
  return dispatch => {
    dispatch({ type: UPLOAD_FILE_REQUEST, loading: true });
    return axios({
      method: 'POST',
      url: `${URL_API}/file`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    }).then(
      response => {
        dispatch({ type: UPLOAD_FILE_SUCCESS, payload: response.data });
      },
      error => {
        dispatch({ type: UPLOAD_FILE_ERROR, error: error.response });
        if (error.response.status === 401) {
          dispatch(logoutUser());
        }
        throw error;
      }
    );
  };
};

export const uploadReceipt = data => {
  const companySession = getCompanySession();
  const token = getToken() || null;
  axios.defaults.headers.common.Authorization = `${token}`;
  return dispatch => {
    dispatch({ type: UPLOAD_RECEIPT_REQUEST, loading: true });
    return axios({
      method: 'POST',
      url: `${GATEWAY_BASE_API_URL}/corporate-credit-card/upload/receipt`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Company-Session': companySession?.id
      },
      data
    }).then(
      response => {
        dispatch({ type: UPLOAD_RECEIPT_SUCCESS, payload: response.data });
      },
      error => {
        dispatch({ type: UPLOAD_RECEIPT_ERROR, error: error.response });
        if (error.response.status === 401) {
          dispatch(logoutUser());
        }
        throw error;
      }
    );
  };
};
