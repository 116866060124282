import httpRequest from '@helpers/httpRequest';
import Swal from 'sweetalert2';
import handleError from '@helpers/handleError';

const BILLING_SET_BILLINGS = 'BILLING_SET_BILLINGS';
const BILLING_SET_TOTAL_BILLINGS = 'BILLING_SET_TOTAL_BILLINGS';
const BILLING_SET_BILLINGS_PAID = 'BILLING_SET_BILLINGS_PAID';
const BILLING_SET_BILLINGS_UNPAID = 'BILLING_SET_BILLINGS_UNPAID';
const BILLING_SET_BILLINGS_DETAIL = 'BILLING_SET_BILLINGS_DETAIL';
const BILLING_SET_BILLING = 'BILLING_SET_BILLING';
const SET_DOWNLOAD_BILLINGS = 'SET_DOWNLOAD_BILLINGS';
const SET_DOWNLOAD_BILLINGS_PDF = 'SET_DOWNLOAD_BILLINGS_PDF';
const SET_DOWNLOAD_BILLINGS_EXCEL = 'SET_DOWNLOAD_BILLINGS_EXCEL';
const SET_DOWNLOAD_BILLINGS_CSV = 'SET_DOWNLOAD_BILLINGS_CSV';
const BILLING_SET_TOTAL_BILLING_PAID = 'BILLING_SET_TOTAL_BILLING_PAID';
const BILLING_SET_TOTAL_BILLING_UNPAID = 'BILLING_SET_TOTAL_BILLING_UNPAID';
const BILLING_SET_TOTAL_BILLING_NEW = 'BILLING_SET_TOTAL_BILLING_NEW';
const BILLING_SET_BILLINGS_DUEDATE = 'BILLING_SET_BILLINGS_DUEDATE';
const BILLING_SET_BILLINGS_LAST_PAID = 'BILLING_SET_BILLINGS_LAST_PAID';
const BILLING_SET_BILLINGS_LAST_UNPAID = 'BILLING_SET_BILLINGS_LAST_UNPAID';
const RESE_PARAM = 'RESE_PARAM';
const SET_SEARCH = 'SET_SEARCH';

export default {
  BILLING_SET_BILLINGS,
  BILLING_SET_BILLINGS_UNPAID,
  BILLING_SET_BILLINGS_PAID,
  BILLING_SET_BILLING,
  BILLING_SET_BILLINGS_DETAIL,
  SET_DOWNLOAD_BILLINGS,
  SET_DOWNLOAD_BILLINGS_PDF,
  SET_DOWNLOAD_BILLINGS_EXCEL,
  SET_DOWNLOAD_BILLINGS_CSV,
  BILLING_SET_TOTAL_BILLINGS,
  BILLING_SET_TOTAL_BILLING_UNPAID,
  BILLING_SET_TOTAL_BILLING_PAID,
  BILLING_SET_TOTAL_BILLING_NEW,
  BILLING_SET_BILLINGS_DUEDATE,
  BILLING_SET_BILLINGS_LAST_PAID,
  BILLING_SET_BILLINGS_LAST_UNPAID,
  RESE_PARAM,
  SET_SEARCH,

  browseBilling(params) {
    return async dispatch => {
      try {
        const billings = await httpRequest.get('/billing', { params });

        dispatch({ type: BILLING_SET_BILLINGS, payload: billings.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseBillingUnpaid(params, callback = Function) {
    return async dispatch => {
      try {
        const billingsUnpaid = await httpRequest.get('/billing/unpaid', {
          params
        });

        dispatch({
          type: BILLING_SET_BILLINGS_UNPAID,
          payload: billingsUnpaid
        });
        callback(true);
      } catch (error) {
        handleError.handleActionError(error);
        callback(false);
      }
    };
  },

  browseBillingPaid(params, callback = Function) {
    return async dispatch => {
      try {
        const billingsPaid = await httpRequest.get('/billing/paid', { params });

        dispatch({
          type: BILLING_SET_BILLINGS_PAID,
          payload: billingsPaid
        });
        callback(true);
      } catch (error) {
        handleError.handleActionError(error);
        callback(false);
      }
    };
  },

  browseBillingDetail(params) {
    return async dispatch => {
      try {
        const billingss = await httpRequest.get('/billing/detail', {
          params
        });
        dispatch({
          type: BILLING_SET_BILLINGS_DETAIL,
          payload: billingss.data
        });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseBillingDueDate(params) {
    return async dispatch => {
      try {
        const billingDueDate = await httpRequest.get('/billing/getDueDate', {
          params
        });
        dispatch({
          type: BILLING_SET_BILLINGS_DUEDATE,
          payload: billingDueDate.data
        });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseBillingLastPaid(params, callback = Function) {
    return async dispatch => {
      try {
        const billingLastPaid = await httpRequest.get('/billing/getLastPaid', {
          params
        });
        // dispatch({
        //   type: BILLING_SET_BILLINGS_LAST_PAID,
        //   payload: billingLastPaid.data
        // });
        callback(billingLastPaid.data.data);
      } catch (error) {
        handleError.handleActionError(error);
        callback(null);
      }
    };
  },

  browseBillingLastUnpaid(params, callback = Function) {
    return async dispatch => {
      try {
        const billingLastUnpaid = await httpRequest.get(
          '/billing/getLastUnpaid',
          {
            params
          }
        );
        // dispatch({
        //   type: BILLING_SET_BILLINGS_LAST_UNPAID,
        //   payload: billingLastUnpaid.data
        // });
        callback(billingLastUnpaid.data.data);
      } catch (error) {
        handleError.handleActionError(error);
        callback(null);
      }
    };
  },

  browseTotalAmount(params) {
    return async dispatch => {
      try {
        const total = await httpRequest.get('/billing/getTotal', { params });
        dispatch({
          type: BILLING_SET_TOTAL_BILLING_UNPAID,
          payload: total.data
        });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseTotalAmountPaid(params) {
    return async dispatch => {
      try {
        const totalPaid = await httpRequest.get('/billing/getTotal', {
          params
        });
        dispatch({
          type: BILLING_SET_TOTAL_BILLING_PAID,
          payload: totalPaid.data
        });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseTotalAmountNew(params) {
    return async dispatch => {
      try {
        const totalNew = await httpRequest.get('/billing/getTotal', { params });
        dispatch({
          type: BILLING_SET_TOTAL_BILLING_NEW,
          payload: totalNew.data
        });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseDownload(params, callback = Function()) {
    return async dispatch => {
      try {
        const download = await httpRequest.get('/billing/html', {
          params
        });
        dispatch({
          type: SET_DOWNLOAD_BILLINGS,
          payload: download.data
        });
        const url = download.data.data.url;
        const strLength = url.length;
        callback(url.substr(2, strLength));
      } catch (error) {
        await Swal.fire('Download failed', '', 'error');
        handleError.handleActionError(error);
        throw error;
      }
    };
  },

  browseDownloadPdf(params, callback = Function()) {
    return async dispatch => {
      try {
        const downloadPdf = await httpRequest.get('/billing/pdf', {
          params
        });
        dispatch({
          type: SET_DOWNLOAD_BILLINGS_PDF,
          payload: downloadPdf.data
        });
        const url = downloadPdf.data.data.url;
        const strLength = url.length;
        callback(url.substr(2, strLength));
      } catch (error) {
        await Swal.fire('Download failed', '', 'error');
        handleError.handleActionError(error);
        throw error;
      }
    };
  },

  browseDownloadExcel(params, callback = Function()) {
    return async dispatch => {
      try {
        const downloadExcel = await httpRequest.get('/billing/excel', {
          params
        });
        dispatch({
          type: SET_DOWNLOAD_BILLINGS_EXCEL,
          payload: downloadExcel.data
        });
        const url = downloadExcel.data.data.url;
        const strLength = url.length;
        callback(url.substr(2, strLength));
      } catch (error) {
        await Swal.fire('Download failed', '', 'error');
        handleError.handleActionError(error);
        throw error;
      }
    };
  },

  browseDownloadCsv(params, callback = Function()) {
    return async dispatch => {
      try {
        const downloadCsv = await httpRequest.get('/billing/csv', {
          params
        });
        dispatch({
          type: SET_DOWNLOAD_BILLINGS_CSV,
          payload: downloadCsv.data
        });
        const url = downloadCsv.data.data.url;
        const strLength = url.length;
        callback(url.substr(2, strLength));
      } catch (error) {
        await Swal.fire('Download failed', '', 'error');
        handleError.handleActionError(error);
        throw error;
      }
    };
  },

  readBilling(id) {
    return async dispatch => {
      try {
        const billing = await httpRequest.get(`/billing/id/${id}`);

        dispatch({ type: BILLING_SET_BILLING, payload: billing.data });
      } catch (error) {
        throw error;
      }
    };
  },

  addBilling(billing, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest.post('/billing', billing);
        await Swal.fire('Success Add New Billing', '', 'success');
        callback();
      } catch (error) {
        await Swal.fire('Error!', 'Email or phone already use', 'error');
        throw error;
      }
    };
  },

  editBilling(id, billing, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest.put(`/billing/id/${id}`, billing);
        await Swal.fire('Success Edit Billing', '', 'success');

        callback();
      } catch (error) {
        await Swal.fire('Failed Edit Billing', '', 'error');
        throw error;
      }
    };
  },

  deleteBilling(billingId) {
    return async dispatch => {
      try {
        await httpRequest.delete(`/billing/id/${billingId}`);
        await Swal.fire('Success Delete Billing', '', 'success');

        const params = {
          search: '',
          page: 1,
          limit: 10
        };
        const billings = await httpRequest.get('/billing', { params });
        dispatch({ type: BILLING_SET_BILLINGS, payload: billings.data });
      } catch (error) {
        await Swal.fire(
          'Failed Create Billing',
          'cant delete billing with activate card',
          'error'
        );
        throw error;
      }
    };
  },
  setDetail(data) {
    return async dispatch => {
      try {
        dispatch({ type: BILLING_SET_BILLINGS, payload: data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },
  resetParams() {
    return async dispatch => {
      dispatch({ type: RESE_PARAM });
    };
  },
  setSearch(data) {
    return async dispatch => {
      dispatch({ type: SET_SEARCH, payload: data });
    };
  }
};
