import { Cookies } from 'react-cookie';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS
} from '../actions/auth.action';
import JwtAuthentication from '../../helpers/jwtAuthentication';

const cookies = new Cookies();

const localToken = JwtAuthentication.checkExpirity(cookies.get('id_token'));

const initState = {
  isFetching: false,
  isAuthenticated: !!localToken.token,
  isLoading: false,
  data: null,
  dataRole: []
};

export default function auth(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        errorMessage: '',
        isLoading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        isLoading: false,
        data: payload.data
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
        isLoading: false
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        isLoading: false
      };

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoadingSubmit: true
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoadingSubmit: false
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoadingSubmit: false
        // data: payload.data
      };

    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.data
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.data
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}
