import httpRequest from '@helpers/httpRequest';
import Swal from 'sweetalert2';
import handleError from '@helpers/handleError';

const BANK_SET_BANKS = 'BANK_SET_BANKS';
const ALL_BANK = 'ALL_BANK';

export default {
  BANK_SET_BANKS,
  ALL_BANK,

  browseBank(page = 1, limit = 10, search = '', status = '') {
    return async dispatch => {
      try {
        const params = {
          page,
          limit,
          search,
          status
        };
        const banks = await httpRequest.get('/bank', { params });

        dispatch({ type: BANK_SET_BANKS, payload: banks.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  listBanks() {
    return async dispatch => {
      try {
        const banks = await httpRequest.get('/bank/list');
        dispatch({ type: ALL_BANK, payload: banks.data });
      } catch (error) {
        handleError.handleActionError(error);
        throw error;
      }
    };
  }
};
