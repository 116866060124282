import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_RECEIPT_REQUEST,
  UPLOAD_RECEIPT_SUCCESS,
  UPLOAD_RECEIPT_ERROR
} from '@actions/upload.action';

const initState = {
  isLoading: false,
  idFile: null,
  idFileReceipt: null
};

export default function UploadReducer(state = initState, action) {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        idFile: action.payload.data.id
      };
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case UPLOAD_RECEIPT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPLOAD_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        idFileReceipt: action.payload.data.id
      };
    case UPLOAD_RECEIPT_ERROR:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}
