import transactionAction from '@actions/transaction.action';

const initState = {
  transactions: null,
  transactionDetail: null,
  transaction: null,
  list: null,
  lastUpdate: '',
  params: {
    page: 1,
    limit: 50,
    dateStart: '',
    dateEnd: '',
    search: ''
  },
  paramsDetail: {
    page: 1,
    limit: 50,
    company: '',
    bank: '',
    dueDate: '',
    cycle: '',
    card: ''
  },
  paramsPdf: {
    card: '',
    bank: '',
    company: '',
    dueDate: ''
  },
  totalAmount: 0
};

function transactionReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case transactionAction.TRANSACTION_SET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload.data,
        pagination: payload.meta
      };

    case transactionAction.TRANSACTION_SET_TRANSACTION:
      return {
        ...state,
        transaction: payload.data
      };

    case transactionAction.TRANSACTION_SET_TRANSACTIONS_DETAIL:
      return {
        ...state,
        transactionDetail: payload.data.data,
        totalAmount: payload.meta.amount.total_amount,
        pagination: payload.meta
      };
    case transactionAction.CARD_CYCLES:
      return {
        ...state,
        list: payload.data
      };
    case transactionAction.RESE_PARAM:
      return {
        ...state,
        params: {
          page: 1,
          limit: 50,
          dateStart: '',
          dateEnd: '',
          search: '',
          cycle: '',
          card: ''
        },
        paramsDetail: {
          page: 1,
          limit: 50,
          company: '',
          bank: '',
          dueDate: ''
        },
        paramsPdf: {
          card: '',
          bank: '',
          company: '',
          dueDate: ''
        }
      };
    case transactionAction.LAST_UPDATE:
      return {
        ...state,
        lastUpdate: payload.data
      };
    default:
      return state;
  }
}

export default transactionReducer;
