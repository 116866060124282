import billingsAction from '@actions/billing.action';
import billingAction from '@actions/billing.action';

const initState = {
  billings: null,
  billingsUnpaid: null,
  billingsPaid: null,
  billingss: null,
  pagination: null,
  paginationPaid: null,
  paginationUnpaid: null,
  download: null,
  total: null,
  totalPaid: null,
  totalNew: null,
  downloadPdf: null,
  downloadExcel: null,
  downloadCsv: null,
  billing: null,
  billingDueDate: null,
  billingLastPaid: null,
  billingLastUnpaid: null,
  params: {
    page: 1,
    limit: 10,
    status: '',
    startDue: '',
    endDue: '',
    card: '',
    year: '',
    month: '',
    name_bank: ''
  },
  paramsDueDate: {
    card: ''
  },
  paramsLastPaid: {
    card: ''
  },
  paramsLastUnpaid: {
    card: ''
  },
  paramsDetail: {
    page: 1,
    limit: 10,
    bank: '',
    dueDate: ''
  },
  paramsHtml: {
    card: '',
    bank: '',
    dueDate: ''
  },
  paramsPdf: {
    card: '',
    bank: '',
    dueDate: ''
  },
  paramsExcel: {
    card: '',
    bank: '',
    dueDate: ''
  },
  paramsCsv: {
    card: '',
    bank: '',
    dueDate: ''
  },
  paramsTotal: {
    status: ''
  },
  paramsPaid: {
    status: ''
  },
  paramsNew: {
    status: ''
  }
};

function billingReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case billingsAction.BILLING_SET_BILLINGS:
      return {
        ...state,
        billings: payload.data,
        pagination: payload.meta
      };

    case billingsAction.BILLING_SET_BILLINGS_UNPAID:
      return {
        ...state,
        billingsUnpaid: payload.data,
        paginationUnpaid: payload.meta
      };

    case billingsAction.BILLING_SET_BILLINGS_PAID:
      return {
        ...state,
        billingsPaid: payload.data,
        paginationPaid: payload.meta
      };

    case billingsAction.SET_DOWNLOAD_BILLINGS:
      return {
        ...state,
        download: payload.data
      };

    case billingsAction.SET_DOWNLOAD_BILLINGS_PDF:
      return {
        ...state,
        downloadPdf: payload.data
      };

    case billingsAction.SET_DOWNLOAD_BILLINGS_EXCEL:
      return {
        ...state,
        downloadExcel: payload.data
      };

    case billingsAction.SET_DOWNLOAD_BILLINGS_CSV:
      return {
        ...state,
        downloadCsv: payload.data
      };

    case billingsAction.BILLING_SET_BILLINGS_DETAIL:
      return {
        ...state,
        billingss: payload.data,
        pagination: payload.meta
      };

    case billingsAction.BILLING_SET_BILLINGS_DUEDATE:
      return {
        ...state,
        billingDueDate: payload.data
      };

    case billingsAction.BILLING_SET_BILLINGS_LAST_PAID:
      return {
        ...state,
        billingLastPaid: payload.data
      };

    case billingsAction.BILLING_SET_BILLINGS_LAST_UNPAID:
      return {
        ...state,
        billingLastUnpaid: payload.data
      };

    case billingsAction.BILLING_SET_TOTAL_BILLING_UNPAID:
      return {
        ...state,
        total: payload.data
      };

    case billingsAction.BILLING_SET_TOTAL_BILLING_PAID:
      return {
        ...state,
        totalPaid: payload.data
      };

    case billingsAction.BILLING_SET_TOTAL_BILLING_NEW:
      return {
        ...state,
        totalNew: payload.data
      };

    case billingsAction.BILLING_SET_BILLING:
      return {
        ...state,
        billing: payload.data
      };
    case billingsAction.SET_SEARCH:
      return {
        ...state,
        params: {
          ...state.params,
          card: payload.card
        }
      };
    case billingAction.RESE_PARAM: {
      return {
        ...state,
        params: {
          page: 1,
          limit: 10,
          status: '',
          startDue: '',
          endDue: '',
          card: ''
        },
        paramsDueDate: {
          card: ''
        },
        paramsLastPaid: {
          card: ''
        },
        paramsLastUnpaid: {
          card: ''
        },
        paramsDetail: {
          page: 1,
          limit: 10,
          bank: '',
          dueDate: ''
        },
        paramsHtml: {
          card: '',
          bank: '',
          dueDate: ''
        },
        paramsPdf: {
          card: '',
          bank: '',
          dueDate: ''
        },
        paramsExcel: {
          card: '',
          bank: '',
          dueDate: ''
        },
        paramsCsv: {
          card: '',
          bank: '',
          dueDate: ''
        },
        paramsTotal: {
          status: ''
        },
        paramsPaid: {
          status: ''
        },
        paramsNew: {
          status: ''
        }
      };
    }
    default:
      return state;
  }
}

export default billingReducer;
