import httpRequest from '@helpers/httpRequest';
import Swal from 'sweetalert2';
import handleError from '@helpers/handleError';
import { GATEWAY_BASE_API_URL } from '@configs/keys';

const TRANSACTION_SET_TRANSACTIONS = 'TRANSACTION_SET_TRANSACTIONS';
const TRANSACTION_SET_TRANSACTIONS_DETAIL =
  'TRANSACTION_SET_TRANSACTIONS_DETAIL';
const TRANSACTION_SET_TRANSACTION = 'TRANSACTION_SET_TRANSACTION';
const RESE_PARAM = 'RESE_PARAM';
const CARD_CYCLES = 'CARD_CYCLES';
const LAST_UPDATE = 'LAST_UPDATE';
const SET_DOWNLOAD_TRANSACTIONS = 'SET_DOWNLOAD_TRANSACTIONS';

const setDownload = (link, name, ext) => {
  return link.setAttribute('download', `${name}.${ext}`);
};

export default {
  TRANSACTION_SET_TRANSACTIONS,
  TRANSACTION_SET_TRANSACTION,
  TRANSACTION_SET_TRANSACTIONS_DETAIL,
  RESE_PARAM,
  CARD_CYCLES,
  LAST_UPDATE,
  SET_DOWNLOAD_TRANSACTIONS,

  browseTransaction(params, callback = Function) {
    return async dispatch => {
      try {
        const transactions = await httpRequest.get('/transaction', { params });

        dispatch({
          type: TRANSACTION_SET_TRANSACTIONS,
          payload: transactions.data
        });
        callback(true);
      } catch (error) {
        handleError.handleActionError(error);
        callback(false);
      }
    };
  },
  browseTransactionDetail(id, params, callback = Function) {
    return async dispatch => {
      try {
        const transactionDetail = await httpRequest({
          url: `corporate-credit-card/transaction/card/${id}`,
          method: 'get',
          baseURL: GATEWAY_BASE_API_URL,
          params
        });
        dispatch({
          type: TRANSACTION_SET_TRANSACTIONS_DETAIL,
          payload: transactionDetail.data
        });
        callback(true);
      } catch (error) {
        handleError.handleActionError(error);
        callback(false);
      }
    };
  },
  readTransaction(id) {
    return async dispatch => {
      try {
        const transaction = await httpRequest({
          url: `corporate-credit-card/transaction/detail/${id}`,
          method: 'get',
          baseURL: GATEWAY_BASE_API_URL
        });
        dispatch({
          type: TRANSACTION_SET_TRANSACTION,
          payload: transaction.data.data
        });
      } catch (error) {
        throw error;
      }
    };
  },
  addReceipt(id, transaction, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest({
          url: `corporate-credit-card/transaction/receipt/${id}`,
          method: 'post',
          baseURL: GATEWAY_BASE_API_URL,
          data: transaction
        });
        callback(true);
      } catch (error) {
        await Swal.fire('Failed Upload Receipt', '', 'error');
        throw error;
      }
    };
  },
  cardCycles(id) {
    return async dispatch => {
      try {
        const cycles = await httpRequest.get(`/transaction/card-cycles/${id}`);
        dispatch({
          type: CARD_CYCLES,
          payload: cycles.data
        });
      } catch (error) {
        await Swal.fire('Error!', '', 'error');
        throw error;
      }
    };
  },
  getLastUpdate() {
    return async dispatch => {
      try {
        const lassUpdate = await httpRequest({
          url: `corporate-credit-card/transaction/last-update`,
          method: 'get',
          baseURL: GATEWAY_BASE_API_URL
        });
        dispatch({ type: LAST_UPDATE, payload: lassUpdate.data });
      } catch (error) {
        const { stat_msg } = error.response.data;
        await Swal.fire('Error!', `getLastUpdate ${stat_msg}`, 'error');
        // throw error;
      }
    };
  },
  downloadTransaction(params, type, callback = Function) {
    return async dispatch => {
      try {
        const data = httpRequest({
          url: `/corporate-credit-card/transaction/${type}`,
          responseType: 'blob',
          params,
          baseURL: GATEWAY_BASE_API_URL,
          method: 'get'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          if (type === 'excel')
            link.setAttribute('download', 'Transaction.xlsx');
          else setDownload(link, 'Transaction', type);
          document.body.appendChild(link);

          link.click();
          callback(true);
        });
        dispatch({
          type: SET_DOWNLOAD_TRANSACTIONS,
          payload: data
        });
      } catch (error) {
        await Swal.fire('Download failed', '', 'error');
        handleError.handleActionError(error);
        throw error;
      }
    };
  },
  resetParams() {
    return async dispatch => {
      dispatch({ type: RESE_PARAM });
    };
  }
};
