import notifAction from '@actions/notif.action';

const initState = {
  list: null,
  allList: null,
  pagination: null,
  count: 0
};

function notifReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case notifAction.SET_NOTIF:
      return {
        ...state,
        list: payload.data
      };

    case notifAction.GET_NOTIF_ALL:
      return {
        ...state,
        allList: payload.data.data,
        pagination: payload.data.meta
      };

    case notifAction.COUNT_NOTIF:
      return {
        ...state,
        count: payload.data.data
      };

    default:
      return state;
  }
}

export default notifReducer;
