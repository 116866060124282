import httpRequest from '@helpers/httpRequest';
import Swal from 'sweetalert2';
import handleError from '@helpers/handleError';

const SETTING_SET_SETTINGS = 'SETTING_SET_SETTINGS';
const SETTING_SET_SETTING = 'SETTING_SET_SETTING';

export default {
  SETTING_SET_SETTINGS,
  SETTING_SET_SETTING,

  readSetting(id) {
    return async dispatch => {
      try {
        const setting = await httpRequest.get(
          `/card_notification/card_id/${id}`
        );

        dispatch({ type: SETTING_SET_SETTING, payload: setting.data });
      } catch (error) {
        throw error;
      }
    };
  },

  addSetting(setting, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest.post('/card_notification', setting);
        await Swal.fire('Success Add New Setting Notification', '', 'success');

        callback();
      } catch (error) {
        await Swal.fire('Failed Create Setting Notification', '', 'error');
        throw error;
      }
    };
  },

  editSetting(id, setting, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest.put(`/card_notification/id/${id}`, setting);
        await Swal.fire('Success Edit Setting Notification', '', 'success');

        callback();
      } catch (error) {
        await Swal.fire('Failed Edit Setting Notificaion', '', 'error');
        throw error;
      }
    };
  }
};
