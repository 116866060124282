import httpRequest from '@helpers/httpRequest';
import Swal from 'sweetalert2';
import handleError from '@helpers/handleError';
import { CARD_STATUS_CODE } from '@constants/cards';
import api from '@configs/api';

const CARD_SET_CARDS = 'CARD_SET_CARDS';
const CARD_SET_CARDSS = 'CARD_SET_CARDSs';
const CARD_SET_LIMIT = 'CARD_SET_LIMIT';
const CARD_SET_CARDS_SPENT = 'CARD_SET_CARDS_SPENT';
const CARD_SET_CARD = 'CARD_SET_CARD';
const CARD_SET_CARDS_ACTIVATION = 'CARD_SET_CARDS_ACTIVATION';
const CARD_SET_CARDS_WAITING = 'CARD_SET_CARDS_WAITING';
const CARD_SET_CARD_TRANSACTION = 'CARD_SET_CARD_TRANSACTION';
const CARD_LIST = 'CARD_LIST';
const CARDS = 'CARDS';
const CARDS_WAITING_ACTIVATION = 'CARDS_WAITING_ACTIVATION';
const CARD_BALANCE = 'CARD_BALANCE';
const CARD_OVERVIEW = 'CARD_OVERVIEW';
const CARD_MAX_REQUEST_LIMIT = 'CARD_MAX_REQUEST_LIMIT';

export default {
  CARD_SET_CARDS_ACTIVATION,
  CARDS,
  CARD_SET_CARD_TRANSACTION,
  CARD_SET_CARDS_WAITING,
  CARD_SET_CARDSS,
  CARD_SET_LIMIT,
  CARD_SET_CARD,
  CARD_SET_CARDS_SPENT,
  CARD_LIST,
  CARD_BALANCE,
  CARDS_WAITING_ACTIVATION,
  CARD_OVERVIEW,
  CARD_MAX_REQUEST_LIMIT,

  browseAllCard() {
    return async dispatch => {
      try {
        const cards = await httpRequest.get('/card/listNumber');
        dispatch({ type: CARD_SET_CARDSS, payload: cards.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseCardActiveCard(params) {
    return async dispatch => {
      try {
        const cards = await httpRequest.get('/card', { params });
        dispatch({ type: CARD_SET_CARDS_ACTIVATION, payload: cards.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseCards(params) {
    return async dispatch => {
      try {
        const cards = await httpRequest.get(api.cards(), {
          params: {
            ...params,
            status: CARD_STATUS_CODE.ACTIVE
          }
        });
        dispatch({ type: CARDS, payload: cards.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },
  browseCardsWaitingActivation(params) {
    return async dispatch => {
      try {
        const cards = await httpRequest.get(api.cards(), {
          params: {
            ...params,
            status: CARD_STATUS_CODE.WAITING_ACTIVATION
          }
        });
        dispatch({ type: CARDS_WAITING_ACTIVATION, payload: cards.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseCardWaitingAactionCard(page = 1, limit = 10, number = '') {
    return async dispatch => {
      try {
        const params = {
          page,
          limit,
          number
        };
        const cards = await httpRequest.get('/card/getAllWaitingActivation', {
          params
        });

        dispatch({ type: CARD_SET_CARDS_WAITING, payload: cards.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseCardLimit() {
    return async dispatch => {
      try {
        const cardss = await httpRequest.get('/card/limit');

        dispatch({ type: CARD_SET_LIMIT, payload: cardss.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  browseCardSpent() {
    return async dispatch => {
      try {
        const cardsSpent = await httpRequest.get('/card/spent');

        dispatch({ type: CARD_SET_CARDS_SPENT, payload: cardsSpent.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  readCard(id, callback = Function()) {
    return async dispatch => {
      try {
        const card = await httpRequest.get(`/card/id/${id}`);

        dispatch({ type: CARD_SET_CARD, payload: card.data });
        callback(true, card.data);
      } catch (error) {
        throw error;
      }
    };
  },

  readCardTransaction(id, callback = Function()) {
    return async dispatch => {
      try {
        const cardTransaction = await httpRequest.get(
          `/card/transaction/${id}`
        );
        dispatch({
          type: CARD_SET_CARD_TRANSACTION,
          payload: cardTransaction.data
        });
        callback(true, cardTransaction.data);
      } catch (error) {
        throw error;
      }
    };
  },

  editCard(id, card, callback = Function()) {
    return async () => {
      try {
        await httpRequest.put(`/card/id/${id}`, card);
        await Swal.fire('Success Edit Card', '', 'success');

        callback();
      } catch (error) {
        await Swal.fire('Failed Edit Card', '', 'error');
        throw error;
      }
    };
  },

  editStatus(id, card, callback = Function()) {
    return async () => {
      try {
        await httpRequest.put(`/card/changeStatus/${id}`, card);
        await Swal.fire('Success Edit Status', '', 'success');

        callback();
      } catch (error) {
        await Swal.fire('Failed Edit Status', '', 'error');
        throw error;
      }
    };
  },

  deleteCard(cardId) {
    return async dispatch => {
      try {
        await httpRequest.delete(`/card/id/${cardId}`);
        await Swal.fire('Success Delete Card', '', 'success');

        const params = {
          search: '',
          page: 1,
          limit: 10
        };
        const cards = await httpRequest.get('/card', { params });
        dispatch({ type: CARD_SET_CARDS, payload: cards.data });
      } catch (error) {
        await Swal.fire('Failed Create Card', '', 'error');
        throw error;
      }
    };
  },

  listCard() {
    return async dispatch => {
      try {
        const cardList = await httpRequest.get('/card/list');
        dispatch({ type: CARD_LIST, payload: cardList.data });
      } catch (error) {
        await Swal.fire('Error!', '', 'error');
        throw error;
      }
    };
  },

  listNumberCard() {
    return async dispatch => {
      try {
        const cardList = await httpRequest.get('/card/listNumber');
        dispatch({ type: CARD_LIST, payload: cardList.data });
      } catch (error) {
        await Swal.fire('Error!', '', 'error');
        throw error;
      }
    };
  },
  browseCardOverview(params) {
    return async dispatch => {
      try {
        const cards = await httpRequest.get(api.cardsOverview(), { params });
        dispatch({ type: CARD_OVERVIEW, payload: cards.data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  getMaxRequestLimit(id) {
    return async dispatch => {
      try {
        const cardRequestLimit = await httpRequest.get(
          api.cardMaxRequestLimit({ id })
        );
        dispatch({
          type: CARD_MAX_REQUEST_LIMIT,
          payload: cardRequestLimit.data
        });
      } catch (error) {
        await Swal.fire('Error getting max request limit!', '', 'error');
        throw error;
      }
    };
  }
};
