import cardsAction from '@actions/cards.action';

const initState = {
  cardsActivation: null,
  cardsWaiting: null,
  cardss: null,
  cardsSpent: null,
  cardTransaction: null,
  paginationActivation: null,
  paginationWaiting: null,
  card: null,
  cardList: null,
  limit: null,
  cardBalance: {},
  paramsActivation: {
    page: 1,
    limit: 10,
    number: ''
  }
};

function cardReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case cardsAction.CARD_SET_CARDS_ACTIVATION:
      return {
        ...state,
        cardsActivation: payload.data,
        paginationActivation: payload.meta
      };
    case cardsAction.CARDS:
      return {
        ...state,
        cards: payload.data
      };
    case cardsAction.CARDS_WAITING_ACTIVATION:
      return {
        ...state,
        cardsWaitingActivation: payload.data
      };
    case cardsAction.CARD_OVERVIEW:
      return {
        ...state,
        cardsOverview: payload.data
      };
    case cardsAction.CARD_SET_CARDS_WAITING:
      return {
        ...state,
        cardsWaiting: payload.data,
        paginationWaiting: payload.meta
      };

    case cardsAction.CARD_SET_CARD:
      return {
        ...state,
        card: payload.data
      };

    case cardsAction.CARD_SET_CARDSS:
      return {
        ...state,
        cardss: payload.data
      };

    case cardsAction.CARD_SET_LIMIT:
      return {
        ...state,
        limit: payload.data
      };

    case cardsAction.CARD_SET_CARDS_SPENT:
      return {
        ...state,
        cardsSpent: payload.data
      };

    case cardsAction.CARD_SET_CARD_TRANSACTION:
      return {
        ...state,
        cardTransaction: payload.data
      };
    case cardsAction.CARD_LIST:
      return {
        ...state,
        cardList: payload.data
      };
    case cardsAction.CARD_BALANCE:
      return {
        ...state,
        cardBalance: {
          ...state.cardBalance,
          [payload.data.id]: payload.data
        }
      };
    case cardsAction.CARD_MAX_REQUEST_LIMIT:
      return {
        ...state,
        cardMaxRequestLimit: payload.data
      };
    default:
      return state;
  }
}

export default cardReducer;
