import axios from 'axios';
import swal from 'sweetalert2';
import { URL_API } from '@configs/keys';
import Router from 'next/router';

export const UPDATE_ACTIVE_REQUEST = 'UPDATE_ACTIVE_REQUEST';
export const UPDATE_ACTIVE_SUCCESS = 'UPDATE_ACTIVE_SUCCESS';
export const UPDATE_ACTIVE_ERROR = 'UPDATE_ACTIVE_ERROR';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export const OTP_REGISTER_REQUEST = 'OTP_REGISTER_REQUEST';
export const OTP_REGISTER_SUCCESS = 'OTP_REGISTER_SUCCESS';
export const OTP_REGISTER_ERROR = 'OTP_REGISTER_ERROR';

export const OTP_RESEND_REQUEST = 'OTP_RESEND_REQUEST';
export const OTP_RESEND_SUCCESS = 'OTP_RESEND_SUCCESS';
export const OTP_RESEND_ERROR = 'OTP_RESEND_ERROR';

export const SET_CODE = 'SET_CODE';

function setOTPSesion(data) {
  localStorage.setItem('otp', JSON.stringify(data));
}

export const setCode = code => dispatch => {
  dispatch({
    type: SET_CODE,
    code
  });
};

export const checkActivate = (data, callback = Function) => dispatch => {
  dispatch({ type: UPDATE_ACTIVE_REQUEST, loading: true });
  return axios({
    method: 'PUT',
    url: `${URL_API}/card/check`,
    data
  }).then(
    response => {
      const otp = { id: response.data.data.id, code: response.data.data.code };
      setOTPSesion(otp);
      dispatch({ type: UPDATE_ACTIVE_SUCCESS, payload: response.data });
      swal
        .fire('Success!', 'We will send OTP to your number phone', 'success')
        .then(() => Router.push('/vertification-otp'));
      callback(true);
      // callback(response.data.data.code);
    },
    error => {
      dispatch({ type: UPDATE_ACTIVE_ERROR, error: error.response });
      swal.fire('Error!', 'Please check your card number or email', 'error');
      callback(false);
      throw error;
    }
  );
};

export const getCardActivationOtp = (id, callback = Function) => dispatch => {
  dispatch({ type: UPDATE_ACTIVE_REQUEST, loading: true });
  return axios({
    method: 'GET',
    url: `${URL_API}/card/${id}/activationOtp`
  }).then(
    response => {
      const otp = { id: response.data.data.id, code: response.data.data.code };
      setOTPSesion(otp);
      dispatch({ type: UPDATE_ACTIVE_SUCCESS, payload: response.data });
      swal
        .fire('Success!', 'We will send OTP to your number phone', 'success')
        .then(() => Router.push('/vertification-otp'));
      callback(true);
    },
    error => {
      dispatch({ type: UPDATE_ACTIVE_ERROR, error: error.response });
      swal.fire('Error!', 'Please check your card number or email', 'error');
      callback(false);
      throw error;
    }
  );
};

export const otpRegister = data => dispatch => {
  dispatch({ type: OTP_REGISTER_REQUEST, loading: true });
  return axios({
    method: 'PUT',
    url: `${URL_API}/card/verifyOtp`,
    data
  }).then(
    response => {
      dispatch({ type: OTP_REGISTER_SUCCESS, payload: response.data });
      // swal.fire('Success!', 'Next set your Password', 'success').then(() => {
      localStorage.removeItem('otp');

      // Router.push(`/set-password`);
      // });
      dispatch(setCode(data.code));
      return response;
    },
    error => {
      dispatch({ type: OTP_REGISTER_ERROR, error: error.response });
      swal.fire('Error!', 'Wrong OTP, please reinput OTP', 'error');
      throw error;
    }
  );
};

export const otpResend = () => dispatch => {
  dispatch({ type: OTP_RESEND_REQUEST, loading: true });
  return axios({
    method: 'PUT',
    url: `${URL_API}/otp/resend`
  }).then(
    response => {
      dispatch({ type: OTP_RESEND_SUCCESS, payload: response.data });
      swal.fire('Success!', 'We will send OTP to your phone number', 'success');
      return response;
    },
    error => {
      dispatch({ type: OTP_RESEND_ERROR, error: error.response });
      swal.fire('Error!', error.response.data.message, 'error');
      throw error;
    }
  );
};

export const setPassword = (data, id, callback = Function) => dispatch => {
  dispatch({ type: SET_PASSWORD_REQUEST, loading: true });
  return axios({
    method: 'PUT',
    url: `${URL_API}/card/requestToActivate/${id}`,
    data
  }).then(
    response => {
      dispatch({ type: SET_PASSWORD_SUCCESS, payload: response.data });
      dispatch(setCode(data.code));

      callback(true);
    },
    error => {
      dispatch({ type: SET_PASSWORD_ERROR, error: error.response });
      swal.fire('Error!', error.response.data.message, 'error');
      callback(false);

      throw error;
    }
  );
};
