import httpRequest from '@helpers/httpRequest';
import Swal from 'sweetalert2';
import handleError from '@helpers/handleError';

const REGISTER_SET_REGISTERS = 'REGISTER_SET_REGISTERS';
const REGISTER_SET_REGISTER = 'REGISTER_SET_REGISTER';

export default {
  REGISTER_SET_REGISTERS,
  REGISTER_SET_REGISTER,

  addRegister(register, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest.post('/company_register', register);
        await Swal.fire(
          'Success!',
          'Thank you for your interest - We will get back to you once we have more updates',
          'success'
        );

        callback();
      } catch (error) {
        await Swal.fire('Failed Register', '', 'error');
        throw error;
      }
    };
  },

  editRegister(id, register, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest.put(`/company_register/id/${id}`, register);
        await Swal.fire('Success Edit Register', '', 'success');

        callback();
      } catch (error) {
        await Swal.fire('Failed Edit Register', '', 'error');
        throw error;
      }
    };
  }
};
