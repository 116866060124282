import { SET_EMPLOYEE_DETAIL } from '@actions/employee.action';

const initialState = {
  data: {}
};

export default function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_DETAIL:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
