import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import app from './app.reducer';
import card from './card.reducer';
import cards from './cards.reducer';
import auth from './auth.reducer';
import billing from './billing.reducer';
import transaction from './transaction.reducer';
import upload from './upload.reducer';
import notif from './notif.reducer';
import setting from './setting.reducer';
import bank from './bank.reducer';
import register from './register.reducer';
import admin from './admin.reducer';
import employee from './employee.reducer';

const appReducer = combineReducers({
  app,
  card,
  cards,
  auth,
  billing,
  transaction,
  upload,
  notif,
  setting,
  bank,
  register,
  admin,
  form: formReducer,
  employee
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
