import settingAction from '@actions/setting.action';

const initState = {
  settings: null,
  pagination: null,
  setting: null
};

function settingReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case settingAction.SETTING_SET_SETTINGS:
      return {
        ...state,
        settings: payload.data,
        pagination: payload.meta
      };

    case settingAction.SETTING_SET_SETTING:
      return {
        ...state,
        setting: payload.data
      };

    default:
      return state;
  }
}

export default settingReducer;
