import registerAction from '@actions/register.action';

const initState = {
  registers: null,
  pagination: null,
  register: null
};

function registerReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case registerAction.REGISTER_SET_REGISTERS:
      return {
        ...state,
        registers: payload.data,
        pagination: payload.meta
      };

    case registerAction.REGISTER_SET_REGISTER:
      return {
        ...state,
        register: payload.data
      };

    default:
      return state;
  }
}

export default registerReducer;
