import bankAction from '@actions/bank.action';

const initState = {
  data: [],
  detail: null,
  pagination: null,
  isLoading: false,
  isLoadingSubmit: false,
  params: {
    page: 1,
    limit: 10,
    search: ''
  }
};

function bankReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'LIST_ADMIN_REQUEST':
      return {
        ...state,
        isLoading: true
      };
    case 'LIST_ADMIN_SUCCESS':
      return {
        ...state,
        data: payload.data,
        pagination: payload.meta,
        isLoading: false
      };
    case 'LIST_ADMIN_ERROR':
      return {
        ...state,
        isLoading: false
      };

    case 'DETAIL_ADMIN_REQUEST':
      return {
        ...state,
        isLoading: true
      };
    case 'DETAIL_ADMIN_SUCCESS':
      return {
        ...state,
        detail: payload.data,
        isLoading: false
      };
    case 'DETAIL_ADMIN_ERROR':
      return {
        ...state,
        isLoading: false
      };

    case 'CREATE_ADMIN_REQUEST':
      return {
        ...state,
        isLoadingSubmit: true
      };
    case 'CREATE_ADMIN_SUCCESS':
      return {
        ...state,
        isLoadingSubmit: false
      };
    case 'CREATE_ADMIN_ERROR':
      return {
        ...state,
        isLoadingSubmit: false
      };

    case 'UPDATE_ADMIN_REQUEST':
      return {
        ...state,
        isLoadingSubmit: true
      };
    case 'UPDATE_ADMIN_SUCCESS':
      return {
        ...state,
        isLoadingSubmit: false
      };
    case 'UPDATE_ADMIN_ERROR':
      return {
        ...state,
        isLoadingSubmit: false
      };

    case 'DELETE_ADMIN_REQUEST':
      return {
        ...state,
        isLoadingSubmit: true
      };
    case 'DELETE_ADMIN_SUCCESS':
      return {
        ...state,
        isLoadingSubmit: false
      };
    case 'DELETE_ADMIN_ERROR':
      return {
        ...state,
        isLoadingSubmit: false
      };
    default:
      return state;
  }
}

export default bankReducer;
