import Swal from 'sweetalert2';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export default {
  async handleActionError(error = Error(), dispatch) {
    // if (error.response != undefined) {
    //   if (error.response.status == 401) {
    // await Swal.fire(
    //   'Session end',
    //   'Please login again to continue',
    //   'info'
    // );
    // cookies.remove('id_token', { path: '/' });
    // window.location.href = '/';
    //   }
    // }
  }
};
