import bankAction from '@actions/bank.action';

const initState = {
  banks: null,
  pagination: null
};

function bankReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case bankAction.BANK_SET_BANKS:
      return {
        ...state,
        banks: payload.data,
        pagination: payload.meta
      };
    case bankAction.ALL_BANK:
      return {
        ...state,
        banks: payload.data
      };
    default:
      return state;
  }
}

export default bankReducer;
