import httpRequest from '@helpers/httpRequest';
import Swal from 'sweetalert2';
import handleError from '@helpers/handleError';

const SET_NOTIF = 'SET_NOTIF';
const GET_NOTIF_ALL = 'GET_NOTIF_ALL';
const COUNT_NOTIF = 'COUNT_NOTIF';

export default {
  SET_NOTIF,
  GET_NOTIF_ALL,
  COUNT_NOTIF,

  notif() {
    return async dispatch => {
      try {
        const params = {
          page: 1,
          limit: 10
        };
        const notif = await httpRequest.get('/notification', { params });
        dispatch({ type: SET_NOTIF, payload: notif });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  allNotif() {
    return async dispatch => {
      try {
        const params = {
          page: 1,
          limit: 20
        };
        const notif = await httpRequest.get('/notification', { params });
        dispatch({ type: GET_NOTIF_ALL, payload: notif });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  countNotif() {
    return async dispatch => {
      try {
        const data = await httpRequest.get('/notification/count-unread');
        dispatch({ type: COUNT_NOTIF, payload: data });
      } catch (error) {
        handleError.handleActionError(error);
      }
    };
  },

  markRead(data, callback = Function()) {
    return async dispatch => {
      try {
        await httpRequest.put(`/notification/set-read`, data);

        callback();
      } catch (error) {
        throw error;
      }
    };
  }
};
